<!--  -->
<template>
  <div class="page">
    <div class="bg">
      <img src="../../assets/img/border.png" alt="" class="bgImg" />
      <div class="head">
        <img src="../../assets/img/line.png" alt="" class="lineImg" />
        生态环境
      </div>
      <div class="list">
        <ul class="leftList">
          <!-- <video id="videoElement"></video> -->
          <li class="leftListItem" v-for="(v,i) in temperature" :key="i">
            <img src="../../assets/img/kuang.png" alt="" class="leftListItemImg">
            <p>{{v.leval}}</p>
            <!-- <p v-html="v.danwei"></p> -->
            <p>
              <span>{{v.name+' '+v.danwei}}</span>
              <span v-if="i==3" style="font-size:8px">(10cm)</span>
              <span v-if="i==4" style="font-size:8px">(30cm)</span>
            </p>
          </li>
        </ul>
        <ul class="rightList">
          <li class="rightListItem">
            <p>{{rain}}</p>
            <img src="../../assets/img/water.png" alt="" class="rightListItemImg">
            <p>雨量 MM</p>
          </li>
          <li class="rightListItem">
            <p>{{sunshine}}</p>
            <img src="../../assets/img/light.png" alt="" class="rightListItemImg">
             <p>光照度 Lux</p>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  data() {
    return {
      temperature:[{name:'空气温度',leval:12,danwei:'℃'},{name:'露点温度',leval:28,danwei:'℃'},{name:'空气湿度',leval:20,danwei:'%'},{name:'土壤温度',leval:56,danwei:'℃'},{name:'土壤温度',leval:56,danwei:'℃'},{name:'土壤湿度',leval:6.2,danwei:'%'}],
      rain:'',
      sunshine:''
    };
  },
  mounted() {
     
    this.$api.environmentInfo({}).then(res=>{
      this.temperature[0].leval=res.data.airTemp
      this.temperature[1].leval=res.data.pointTemp
      this.temperature[2].leval=res.data.airHumidity
      this.temperature[3].leval=res.data.landTemp10
      this.temperature[4].leval=res.data.landTemp30
      this.temperature[5].leval=res.data.landHumidity
      this.rain=res.data.rain
      this.sunshine=res.data.sunshine
    })
    

  },
  methods: {},
};
</script>
<style scoped>
li{
  list-style: none;
}
.bg {
  width: 481px;
  height: 343px;
  position: relative;
}
.bgImg {
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: -1;
}
.head {
  height: 20px;
  
  padding-top: 20px;
  width: 90%;
  margin: 0 auto;
  font-size: 14px;

  font-weight: bold;

  color: #ffffff;
}
.lineImg {
  width: 90%;
  position: absolute;
  top: 23px;
  left: 0;
  right: 0;
  margin: 0 auto;
}
.list{
  display: flex;
  justify-content: space-between;
  height: 75%;
  margin-top: 31px;
  color: #fff;
  text-align: center;
  font-size: 12px;
}
.leftList{
  width: 76%;
  display: flex;
  justify-content: flex-end;
  align-content: space-around;
  flex-wrap: wrap;
  /* background-color: #fff; */
}
.rightList{
  width: 22%;
  display: flex;
  justify-content: space-around;
  align-content: space-around;
  flex-wrap: wrap;
  /* background-color: black; */
}
.leftListItem{
  width: 30%;
  margin-left: 3%;
  
  position: relative;
 
}
.leftListItemImg{
  width: 90%;
}
.leftListItem p{
  position: absolute;
  
}
.leftListItem>p:first-of-type{
  font-size: 12px;
  font-weight: bold;
  top: 20%;
  left: 0;
  right: 0;
  margin: 0 auto;

}
/* .leftListItem>p:nth-of-type(2){
  top: 30%;
  left: 0;
  right: 0;
  margin: 0 auto;
} */
.leftListItem>p:nth-of-type(2){
  color: rgba(255, 255, 255, 0.8);
  /* background-color: red; */
  top: 63%;
  left: 0;
  right: 0;
  margin: 0 auto;
   display: flex;
  align-items: center;
  height: 30px;
  /* background-color: rebeccapurple; */
  /* align-content: center; */
  flex-wrap: wrap;
  
}
.leftListItem>p:nth-of-type(2) span{
  display: block;
  width: 100%;
  
  font-size: 5px;
  line-height: 15px;
}
.leftListItem>p:nth-of-type(2) span:nth-of-type(2){
  font-size: 8px !important;
  
}
.rightListItem{
  text-align: center;
  
}
.rightListItem>p:first-of-type{
  font-weight: bold;
  font-size: 16px;
}
.rightListItem>p:nth-of-type(2){
  color: rgba(255, 255, 255, 0.8);
}
.rightListItemImg{
  width: 90%;
}
</style>
