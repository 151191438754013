<!--  -->
import { clearInterval } from 'timers';
<template>
  <div class="page">
    <div class="bg">
      <img src="../../assets/img/teaBg.png" alt="" class="bgImg" />
      <div class="head">
        <img src="../../assets/img/line.png" alt="" class="lineImg" />
        资讯报导
      </div>
      <div class="newsList" @mouseover="mouseOver" @mouseout="mouseOut">
        <ul id="con1" ref="con1" :class="{ anim: animate == true }">
          <li v-for="(item, key) in items" :key="key" class="newsItem">
            <img src="../../assets/img/icon.png" alt="" style="width:20px;height:20px">
            <span>{{ item.title }}</span>
            <span @click="getCode(item.qrCode)">查看></span>
          </li>
        </ul>
        <div class="showCode" v-if="codeShow">
         <p style="text-align:right;padding-right:10px;cursor:pointer" @click="codeShow=false">x</p>
          <img :src="code" alt="">
          <p>更多内容可扫码查看</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      items: [
        { name: "没有茶产业的大盘高涨 就没有茶叶企业没有茶产业的大盘高涨没有茶产业的大盘高涨" },
        { name: "没有" },
        { name: "今天王鹏是个大笨猪" },
        { name: "搜狗" },
      ],
      codeShow:false,
      animate:true,
      code:'',
      timer:''
    };
  },
  mounted() {
    this.timer=setInterval(this.scroll, 2000);
    this.$api.newsList({
      limit:12,
      page:1
    }).then(res=>{
      this.items=res.data.records
    })
  },
  methods: {
    scroll() {
      this.animate = true; // 因为在消息向上滚动的时候需要添加css3过渡动画，所以这里需要设置true
      setTimeout(() => {
        //  这里直接使用了es6的箭头函数，省去了处理this指向偏移问题，代码也比之前简化了很多
        this.items.push(this.items[0]); // 将数组的第一个元素添加到数组的
        this.items.shift(); //删除数组的第一个元素
        this.animate = false; // margin-top 为0 的时候取消过渡动画，实现无缝滚动
      }, 1000);
    },
    getCode(qrCode){
      this.code=qrCode
      this.codeShow=true
    },
    mouseOver(){
      //console.log('鼠标悬停')
      // this.animate = false;
      clearInterval(this.timer)
    },
    mouseOut(){
      // this.animate = true;
      this.timer=setInterval(this.scroll, 2000);
    }
  },
};
</script>
<style scoped>
.bg {
  width: 481px;
  height: 185px;
  position: relative;
}
.bgImg {
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: -1;
}
.head {
  height: 20px;

  padding-top: 20px;
  width: 90%;
  margin: 0 auto;
  font-size: 14px;

  font-weight: bold;

  color: #ffffff;
}
.lineImg {
  width: 90%;
  position: absolute;
  top: 23px;
  left: 0;
  right: 0;
  margin: 0 auto;
}
.newsList {
  width: 90%;
  margin: 20px auto;
  height: 90px;
  overflow: hidden;
  font-size: 12px;
  font-family: PingFang SC;
  font-weight: 400;
  line-height: 17px;
  color: #ffffff;
   /* background-color: red; */
}
.anim {
  transition: all 1s;
  margin-top: -30px;
}
#con1 li {
  list-style: none;
  line-height: 30px;
  height: 30px;
}
.newsItem span:first-of-type{
  display: inline-block;
  width: 340px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.newsItem span:nth-of-type(2){
  cursor: pointer;
  vertical-align: middle
}
.newsItem{
  width: 100%;
  display: flex;
  justify-content: space-between;
  
}
.showCode{
  width: 120px;
  height: 126px;
  background: rgba(8, 25, 49, 0.35);
  box-shadow: 0px 0px 6px rgba(255, 255, 255, 0.16);
  position: absolute;
  bottom: 0;
  right: 25px;
  text-align: center;
  font-size: 10px;
  background: rgba(8, 25, 49, 0.8);
  color: rgba(255, 255, 255, 0.8);
  
}
.showCode img{
  width: 70%;
  margin: 0px 0 5px;
}
</style>
