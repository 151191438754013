<!--  -->
<template>
  <div class="page">
    <div class="bg">
      <img src="../../assets/img/border.png" alt="" class="bgImg" />
      <div class="head">
        <img src="../../assets/img/line.png" alt="" class="lineImg" />
        苗木观测
      </div>
      <div class="swiper">
        <el-carousel :interval="3000" type="card" height="55px" loop>
          <el-carousel-item v-for="(item,key) in swiperImgArr" :key="key">
            <img :src="item.originalUrl" alt="" class="swiperImg" style="width:100%" v-image-preview="item" @click="bigImg(key)"/>
          </el-carousel-item>
        </el-carousel>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      // swiperImgArr:[require('../../assets/img/1.jpg'),require('../../assets/img/2.jpg'),require('../../assets/img/3.jpg'),require('../../assets/img/4.jpg'),require('../../assets/img/5.jpg'),require('../../assets/img/6.jpg'),require('../../assets/img/7.jpg'),require('../../assets/img/8.jpg'),require('../../assets/img/9.jpg'),require('../../assets/img/10.jpg')],
      swiperImgArr:[]
    };
  },
  mounted() {
    this.$api.plantInfo({}).then(res=>{
      this.swiperImgArr=res.data
    })
  },
  methods: {
    bigImg(key){
      
      console.log('11')
    }
  },
};
</script>
<style scoped>
.bg {
  width: 481px;
  height: 279px;
  position: relative;
}
.bgImg {
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: -1;
}
.head {
  height: 20px;

  padding-top: 20px;
  width: 90%;
  margin: 0 auto;
  font-size: 14px;

  font-weight: bold;

  color: #ffffff;
}
.lineImg {
  width: 90%;
  position: absolute;
  top: 23px;
  left: 0;
  right: 0;
  margin: 0 auto;
}
.swiper {
  /* background-color: #fff; */
  width: 90%;

  margin: 50px auto 0;
}
.el-carousel__item, .el-carousel__mask{
   height: 80px;
   background-color: transparent !important;
 }

  .el-carousel__mask {
    
    opacity: 0 !important;
  }
  .el-carousel__arrow{
    display: none !important;
  }
</style>
