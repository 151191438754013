<!--  -->
<template>
  <div class="page">
    <div class="bg">
      <img src="../../assets/img/border.png" alt="" class="bgImg" />
      <div class="head">
        <img src="../../assets/img/line.png" alt="" class="lineImg" />
        数据分析
      </div>
      <div class="echarts">
        <ul class="tabBar">
          <li
            v-for="(v, i) in tabTittle"
            :key="i"
            @click="changeActiveTabIndex(i)"
            :class="activeTabIndex == i ? 'activeTab' : 'normalTab'"
          >
            {{ v }}
          </li>
        </ul>
        <div v-show="activeTabIndex == 0" class="shipment">
          <div class="date">
            <el-date-picker
              v-show="activeDate == 1"
              v-model="shipmentDate"
              type="month"
              placeholder="选择月"
              value-format="yyyy,MM"
              :clearable="false"
              :editable="false"
            >
            </el-date-picker>
            <el-date-picker
              v-show="activeDate == 0"
              v-model="valueYear"
              type="year"
              placeholder="选择年"
              value-format="yyyy"
              :clearable="false"
              :editable="false"
            >
            </el-date-picker>
            <span
              :class="activeDate == 0 ? 'active' : ''"
              @click="changeDate(0)"
              >年</span
            >
            <span
              :class="activeDate == 1 ? 'active' : ''"
              @click="changeDate(1)"
              >月</span
            >
          </div>
          <div id="myChart1"></div>
        </div>
        <div v-show="activeTabIndex == 1" class="shipment">
          <div class="date">
            <el-date-picker
             v-show="activeDate == 1"
              v-model="shipmentDate"
              type="month"
              placeholder="选择月"
              value-format="yyyy,MM"
              @change="selectDate"
              :clearable="false"
              :editable="false"
            >
            </el-date-picker>
            <el-date-picker
              v-show="activeDate == 0"
              v-model="valueYear"
              type="year"
              placeholder="选择年"
              value-format="yyyy"
              :clearable="false"
              :editable="false"
            >
            </el-date-picker>
            <span
              :class="activeDate == 0 ? 'active' : ''"
              @click="changeDate(0)"
              >年</span
            >
            <span
              :class="activeDate == 1 ? 'active' : ''"
              @click="changeDate(1)"
              >月</span
            >
          </div>
          <div id="myChart3"></div>
        </div>
        <div v-show="activeTabIndex == 2" class="price">
          <price ref="price"></price>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import price from "./price.vue";

export default {
  components: { price },
  data() {
    return {
      tabTittle: ["采摘数量", "产茶数量", "销售数量"],
      myChart1:'',
      myChart3:'',
      activeTabIndex: 0,
      activeDate: 1,
      shipmentDate: "",
      month: [],
      days: [],
      valueYear: "",
      timeStr: "",
      echartsOption: {
        tooltip: {
          show: true,
          trigger: "axis",
          textStyle: {
                fontSize: 5
            },
          formatter:  (params, ticket, callback)=> {
            var htmlStr = "";
            for (var i = 0; i < params.length; i++) {
              var param = params[i];
              var xName = param.name; //x轴的名称
              var seriesName = param.seriesName; //图例名称
              var value = param.value; //y轴值
              var color = param.color; //图例颜色
              // //console.log('param',param)
              if (i === 0) {
                htmlStr += this.timeStr+'-'+(xName>9?xName:'0'+xName) + "<br/>"; //x轴的名称
              }
              htmlStr += "<div style='line-height:20px'>";

              // 具体显示的数据【字段名称：seriesName，值：value】
              // 这里判断一下name，如果是我们需要特殊处理的，就处理
              if (seriesName === "额外信息") {
                // 前面一条线，后面一条线【具体样式自己写】
                htmlStr += '<div style="border: 1px solid #FFEB3B"></div>';
                htmlStr += "XXXXX：" + value;
                htmlStr += '<div style="border: 1px solid #FFEB3B"></div>';
              } else {
                // 正常显示的数据，走默认
                htmlStr +=
                  '<span style="margin-right:5px;display:inline-block;width:10px;height:10px;border-radius:5px;background-color:' +
                  color +
                  ';"></span>';
                htmlStr +=this.tabTittle[this.activeTabIndex]+':'+  value[1];
              }

              htmlStr += "</div>";
            }
            return htmlStr;
          },
        },
        grid: {
          width: "86%",
          height: "76%",
          bottom: "0%",
          left: "1%",
          containLabel: true,
        },
        xAxis: {
          data: [],
          min: 0,
          name: "日",
          axisLine: {
            lineStyle: {
              color: "#DCD2CF",
            },
          },
          nameTextStyle: {
                
                fontSize: this.transformFontSize(15)
            },
          axisLabel: {
                  textStyle: {
                    fontSize: this.transformFontSize(15),
                    
                  },
                },
          boundaryGap: true,
          axisTick: {
            show: false,
          },
        },
        yAxis: {
          min: 0,
          max: 1000,
          name: "斤",
          axisLine: {
            lineStyle: {
              color: "#DCD2CF",
            },
          },
          nameTextStyle: {
                
                fontSize: 15
            },
          axisLabel: {
                  textStyle: {
                    fontSize: 15,
                    
                  },
                },
          type: "value",

          axisTick: {
            show: false,
          },
          splitLine:{
            lineStyle:{
              type:'dashed',
              color:'#4D5B6B'
            }
          }
        },
        series: [
          {
            name: "",
            type: "line",
            data: [
              [28, 103],
              [5, 100.12],
              [6, 100],
              [7, 101.9],
            ],
            smooth: true,
            itemStyle: {
              normal: {
                color: "#98FEFD", //改变折线点的颜色
                lineStyle: {
                  color: "#98FEFD", //改变折线颜色
                },
              },
            },
          },
        ],
      },
    };
  },
  watch: {
    valueYear() {
      if (this.activeDate == 0) {
        this.getTrendData();
      }
    },
    shipmentDate() {
      if (this.activeDate == 1) {
        this.getTrendData();
      }
    },
    echartsOption: {
      handler: function (n, o) {
        //console.log("变化");
      },
      deep: true,
    },
  },
  mounted() {
    //填充月份
    this.month = new Array(12).fill(1).map((item, index) => {
      return 1 + index;
    });

    this.getDays(this.doHandleDate().split(",")[1] - 0);

    this.echartsOption.xAxis.data = this.days;
    this.echartsOption.xAxis.name = "日";
    this.drawLine();
    this.shipmentDate = this.doHandleDate();
    this.valueYear = this.doHandleDate().split(",")[0];
    //console.log(this.shipmentDate);
    // this.getTrendData()
    //console.log("store", this.$store.state.timeStr);
    // window.onresize=function(){
    //   this.drawLine()
    // }
    window.addEventListener("resize", () => {
      this.$nextTick(() => {
        this.myChart1.resize();
        this.myChart3.resize();
      });
    });
  },
  methods: {
    transformFontSize(fontsize) {
      const width = window.screen.width
      const ratio = width / 1920
      return parseInt(fontsize * ratio)
    },

    getTrendData() {
      if (this.activeDate == 0) {
        this.timeStr = this.valueYear;
      } else {
        if (this.shipmentDate) {
          this.timeStr =
            this.shipmentDate.split(",")[0] +
            "-" +
            this.shipmentDate.split(",")[1];
        }
      }
      if (this.timeStr) {
        this.$api
          .getTrendData({
            dataType: this.activeTabIndex + 1,
            timeStr: this.timeStr,
          })
          .then((res) => {
            //console.log(res);
            let arr = [];
            let max = 10;
            for (let i in res.data) {
              // if(Number(res.data[i].timeStr>0)){
              //   arr.push([Number(res.data[i].timeStr) - 1, res.data[i].amount]);
              // }else{
              //   arr.push([Number(res.data[i].timeStr), res.data[i].amount]);
              // }
              arr.push([Number(res.data[i].timeStr) - 1, res.data[i].amount]);
              max = res.data[i].amount > max ? res.data[i].amount : max;
              
            }
            
            (this.echartsOption.series = [
              {
                name: "",
                type: "line",
                data: arr,
                smooth: true,
                itemStyle: {
                  normal: {
                    color: "#98FEFD", //改变折线点的颜色
                    lineStyle: {
                      color: "#98FEFD", //改变折线颜色
                    },
                  },
                },
                // areaStyle: {
                //   color: "linear-gradient(180deg, #0080FF 0%, rgba(24, 127, 196, 1) 100%);",
                //   opacity: 0.5,
                // },
              },
            ]),
              (this.echartsOption.yAxis = {
                min: 0,
                max: max,
                name: "斤",
                axisLine: {
                  lineStyle: {
                    color: "#DCD2CF",
                  },
                },
                axisLabel: {
                  textStyle: {
                    fontSize: this.transformFontSize(15),
                    
                  },
                },
                nameTextStyle: {
                
                fontSize: this.transformFontSize(15)
            },
                axisTick: {
                  show: false,
                },
                splitLine:{
                  lineStyle:{
                    type:'dashed',
                    color:'#4D5B6B'
                  }
                }
              }),
              // this.echartsOption.series.data=[[2,103],[10,500.12],[15,700],[30,901.9]]
              this.$nextTick(() => {
                //console.log("series", this.echartsOption.series.data);
                this.drawLine();
              });
          });
      }
    },
    changeActiveTabIndex(i) {
      this.activeTabIndex = i;
      if (i != 2) {
        this.getTrendData();
      } else {
        this.$refs.price.getTrendData();
      }
      
    },
    drawLine() {
      this.myChart1 = this.$echarts.init(document.getElementById("myChart1"));
      this.myChart1.setOption(this.echartsOption, true);
      this.myChart3 = this.$echarts.init(document.getElementById("myChart3"));
      this.myChart3.setOption(this.echartsOption, true);
      this.myChart3.resize();
      console.log("series2", this.myChart1);
    },
    changeDate(value) {
      this.activeDate = value;
      if (value == 0) {
        //年
        this.echartsOption.xAxis.data = this.month;
        this.echartsOption.xAxis.name = "月";
        // this.drawLine();
      } else {
        //月
        this.echartsOption.xAxis.data = this.days;
        this.echartsOption.xAxis.name = "日";
        // this.drawLine();
      }
      this.myChart1|this.myChart3.clear
      this.getTrendData();
    },
    selectDate(value) {
      //console.log(value);
      let year = value.split(",")[0];
      let mon = value.split(",")[1];
      this.getDays(mon - 0);
      if (this.activeDate == 1) {
        this.echartsOption.xAxis.data = this.days;
        this.drawLine();
      }
    },
    //获取当前时间
    doHandleDate() {
      var myDate = new Date();
      var tYear = myDate.getFullYear();
      var tMonth = myDate.getMonth();

      var m = tMonth + 1;
      if (m.toString().length == 1) {
        m = "0" + m;
      }
      return tYear + "," + m;
    },
    getDays(mon) {
      //传入月份mon  类型为number

      let day_31 = [1, 3, 5, 7, 8, 10, 12];
      let day_30 = [4, 6, 9, 11];
      let year = this.doHandleDate().split(",")[0];
      //月份为31天
      if (day_31.indexOf(mon) != -1) {
        this.days = new Array(31).fill(1).map((item, index) => {
          return 1 + index;
        });
        return false;
      }
      //月份为30天
      if (day_30.indexOf(mon) != -1) {
        this.days = new Array(30).fill(1).map((item, index) => {
          return 1 + index;
        });
        return false;
      }
      //月份为2时判断当前是平年还是闰年
      //闰年
      if ((year % 4 == 0 && year % 100 !== 0) || year % 400 == 0) {
        this.days = new Array(29).fill(1).map((item, index) => {
          return 1 + index;
        });
        return false;
      }
      //平年
      this.days = new Array(28).fill(1).map((item, index) => {
        return 1 + index;
      });
    },
  },
};
</script>
<style scoped lang="scss">
li {
  list-style: none;
}
.bg {
  width: 481px;
  height: 391px;
  margin: 23px 0;
  position: relative;
  // background-color: red;
}
.bgImg {
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: -1;
}
.head {
  height: 20px;

  padding-top: 20px;
  width: 90%;
  margin: 0 auto;
  font-size: 14px;

  font-weight: bold;

  color: #ffffff;
}
.lineImg {
  width: 90%;
  position: absolute;
  top: 23px;
  left: 0;
  right: 0;
  margin: 0 auto;
}
.tabBar {
  width: 90%;
  margin: 10px auto;
  display: flex;
  justify-content: space-between;
}
.tabBar li {
  width: 33%;
  height: 27px;
  line-height: 27px;
  text-align: center;
  /* background: linear-gradient(180deg, rgba(102, 255, 255, 0.02) 0%, rgba(0, 128, 255, 0.2) 100%); */
  font-size: 12px;

  color: rgba(255, 255, 255, 0.8);
  cursor: pointer;
}
.normalTab {
  background: linear-gradient(
    180deg,
    rgba(102, 255, 255, 0.02) 0%,
    rgba(0, 128, 255, 0.2) 100%
  );
}
.activeTab {
  background: linear-gradient(
    180deg,
    rgba(102, 255, 255, 0.02) 0%,
    rgba(0, 128, 255, 0.5) 100%
  );
  border-bottom: 2px solid #0080ff;
}
#myChart1 {
  width: 100%;
  height: 250px;
  // padding-top: 10px;
  // box-sizing: border-box;
  margin: 0px auto;
  // background: green;
  
}
#myChart3 {
  width: 100%;
  height: 250px;
  margin: 0px auto;
}
.price {
  width: 95%;
  height: 270px;
  margin: 0px auto;
}
.shipment {
  width: 90%;
  margin: 0 auto;
  .date {
    background: transparent;
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
    span {
      display: inline-block;
      width: 50px;
      height: 35px;
      border: 1px solid rgba(102, 255, 255, 0.09803921568627452);
      border-radius: 2px;
      text-align: center;
      line-height: 35px;
      color: white;
      cursor: pointer;
      &:first-of-type {
        margin-right: 5px;
      }
    }
    .active {
      border-color: rgb(2, 173, 253);
      border-radius: 2px;
    }
  }
  .el-input__inner {
    border: 1px solid rgba(102, 255, 255, 0.09803921568627452) !important;
  }
}
</style>
