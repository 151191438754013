<!--  -->
<template>
  <div class="page">
    <div class="bg">
      <img src="../../assets/img/border.png" alt="" class="bgImg" />
      <div class="head">
        <img src="../../assets/img/line.png" alt="" class="lineImg" />
        茶艺文化
      </div>
      <div class="videoPart">
        <video
          :src="videoSrc"
          ref="videoPlayer"
          controls="controls"
          v-if="type == 0"
          autoplay
          muted
          loop
          @dblclick="allScreen"
          class="myVideo"
        ></video>
        <video
          id="myVideo"
          class="myVideo video-js vjs-default-skin vjs-big-play-centered"
          autoplay
          muted
          loop
          ref="videoPlayer"
          controls="controls"
          preload="auto"
          data-setup="{}"
          v-else
        >
          <source id="source" :src="videoSrc" type="application/x-mpegURL" />
        </video>
        
        <!-- <div v-else style="text-align:center;font-size: 10px;color: rgba(255, 255, 255, 0.8);line-height:40px"><img src="../../assets/img/noData.png" alt=""><p>暂无数据</p></div> -->
      </div>
    </div>
  </div>
</template>

<script>
// import videojs from "video.js";
// import "videojs-contrib-hls";
// import "@videojs/http-streaming";
export default {
  data() {
    return {
      videoSrc:
        "http://39.174.72.5:7086/live/cameraid/1000077%240/substream/1.m3u8",
      type: 0,
    };
  },
  mounted() {
    this.$api.queryVideo({}).then((res) => {
      this.videoSrc = res.data.url;
      
      this.type = res.data.type;
      if (this.type == 1) {
        this.$nextTick(() => {
          //console.log(Date.now())
          var myVideo = window.videojs('myVideo', {
            loop: true,
          controls: true,
          preload: 'auto',
          autoplay: true,
          	bigPlayButton: true,
          	textTrackDisplay: false,
          	posterImage: false,
          	errorDisplay: false,
          })
          // myVideo.play()
          // var changeVideo = function(videoSrc) {
            //console.log("hhhh")
          	if (/\.m3u8$/.test(this.videoSrc)) {
          		myVideo.src({
          			src: this.videoSrc,
          			type: 'application/x-mpegURL'
          		})
          	} else {
          		myVideo.src(this.videoSrc)
          	}
          	myVideo.load();
          	myVideo.play();
          
        });
      }
    });
  },
  methods: {
    allScreen() {
      //console.log("allScreen");
    },
  },
};
</script>
<style scoped>
.bg {
  width: 481px;
  height: 289px;
  position: relative;
}
.bgImg {
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: -1;
}
.head {
  height: 20px;

  padding-top: 20px;
  width: 90%;
  margin: 0 auto;
  font-size: 14px;

  font-weight: bold;

  color: #ffffff;
}
.lineImg {
  width: 90%;
  position: absolute;
  top: 23px;
  left: 0;
  right: 0;
  margin: 0 auto;
}
.videoPart {
  width: 90%;
  height: 180px;
  margin: 35px auto;
  /* background-color: #fff; */
  text-align: center;
}
.videoPart .myVideo {
  width: 100%;
  height: 180px;
  background-color: black;
  /* object-fit:fill; */
}
</style>