<!--  -->
<template>
<div class='page'>
  <top></top>
  <middle></middle>
  <bottom></bottom>
</div>
</template>

<script>
import top from '../components/right/top'
import middle from '../components/right/middle'
import bottom from '../components/right/bottom'
export default {
data() {
return {

};
},
components:{
  top,middle,bottom
},
mounted() {

},
methods: {

},
}
</script>
<style scoped>

</style>