<!--  -->
<template>
<div class='page'>
<one></one>
<two></two>
<three></three>
</div>
</template>

<script>
import one from '../components/left/one'
import  two from '../components/left/two'
import three from '../components/left/three'
export default {
data() {
return {

};
},
components:{
  one,two,three
},
mounted() {

},
methods: {

},
}
</script>
<style scoped>

</style>