<template>
  <div class="page">
    <div class="box">
      <img src="../assets/img/BG.png" alt="" class="BG" />
      <div class="header" @click="fullScreen">
        <img src="../assets/img/headerBg.png" alt="" class="headerBg" />
        <div class="content">
          <div class="leftContent">
            <img :src="todayTianqi" alt="" class="sunnyImg" />
            <span
              style="font-size: 18px; font-weight: bold; margin: 0 20px 0 10px"
              >{{ weather.weather }}</span
            >
            <span style="font-size: 10px; margin-right: 30px">{{
              weather.temperature
            }}</span>

            <span style="margin-right: 10px; font-size: 10px">{{
              weather.riqi
            }}</span>
            <span style="display: block; font-size: 10px">{{
              weather.week
            }}</span>

            <span>
              <!-- <img src="../assets/img/clock.png" alt="" /> -->
              {{ weather.time }}
            </span>
          </div>
          <div class="centerContent">数智茶园</div>
          <div class="rightContent">
            <img src="../assets/img/juxing1.png" alt="" />
            <img src="../assets/img/juxing1.png" alt="" />
            <img src="../assets/img/juxing1.png" alt="" />
            <img
              src="../assets/img/row.png"
              alt=""
              
              
              @click="goHome"
            />
            <a
              href="http://218.108.197.246:1080/big-screen.html#/big-screen/manage"
              >数智安顶</a
            >
            <a
              href="http://218.108.197.246:1080/big-screen.html#/big-screen/travel"
              >数智旅游</a
            >
            <a
              href="https://smart.topyn.cn/login/sso/postLogin?loginName=17767132289&password=dc483e80a7a0bd9ef71d8cf973673924&appId=9JN4dN&productId=m4J68S&valiCap=unva"
              >数智云台</a
            >
          </div>
        </div>
      </div>
      <div class="body">
        <div class="leftBody">
          <left-body></left-body>
        </div>
        <div class="centerBody">
          <img src="../assets/img/map.png" alt="" class="map" />

          <p
            style="
              font-size: 9px;
              font-weight: bold;
              color: rgba(255, 255, 255, 0.8);
              text-align: center;
            "
          >
            茶园总面积(亩)
          </p>
          <ul class="areaList">
            <!-- <li v-for="(v, i) in area" :key="i">
              <p>{{ v }}</p>
              <img src="../assets/img/area.png" alt="" />
            </li> -->
            <img src="../assets/img/areaC.png" alt="" />
          </ul>
          <ul class="device">
            <el-checkbox-group v-model="radio" @change="checkBoxChange">
              <li><el-checkbox label="0">苗木观测</el-checkbox></li>
              <li><el-checkbox label="1">无线墒情</el-checkbox></li>
              <li><el-checkbox label="2">虫情测报</el-checkbox></li>
              <li><el-checkbox label="3">周边安全</el-checkbox></li>
            </el-checkbox-group>
          </ul>
          <span
            v-for="item in 16"
            :key="item"
            :class="'point ' + pointArr[item - 1]"
          >
            <!-- 六个点 -->
            <!-- <img src="../assets/img/badChong.png" alt="" v-if="radio.indexOf('2')!=-1&&item==5"> -->
            <!-- 虫情测报 -->
            <img
              :src="picList[2]"
              alt=""
              v-if="radio.indexOf('2') != -1 && item == 5"
              @click="changeShow(5)"
              style="z-index: 1000; position: absolute"
              :class="'sixImg img' + item"
            />
            <!-- 无线墒情 -->
            <img
              :src="picList[1]"
              alt=""
              v-if="radio.indexOf('1') != -1 && item == 4"
              @click="changeShow(4)"
              style="z-index: 1000; position: absolute"
              :class="'sixImg img' + item"
            />
            <!-- 苗木观测 -->
            <img
              :src="picList[0]"
              alt=""
              v-if="radio.indexOf('0') != -1 && item == 2"
              @click="changeShow(2)"
              style="z-index: 1000; position: absolute"
              :class="'sixImg img' + item"
            />
            <!-- 周边 修改  苗木-->
            <img
              :src="picList[0]"
              alt=""
              v-if="radio.indexOf('0') != -1 && item == 1"
              @click="changeShow(1)"
              style="z-index: 1000; position: absolute"
              :class="'sixImg img' + item"
            />
            <!-- 周边新增5台设备12 13 14 15 15 -->
            <img
              :src="picList[0]"
              alt=""
              v-if="
                radio.indexOf('3') != -1 &&
                (item == 7 ||
                  item == 8 ||
                  item == 9 ||
                  item == 10 ||
                  item == 11)
              "
              @click="changeShow(item)"
              style="z-index: 1000; position: absolute"
              :class="'sixImg img' + item"
            />
            <!-- 苗木观测新增5台设备12 13 14 15 15 -->
            <!-- <img
              :src="picList[0]"
              alt=""
              v-if="
                radio.indexOf('0') != -1 &&
                (item == 12 ||
                  item == 13 ||
                  item == 14 ||
                  item == 15 ||
                  item == 16)
              "
              @click="changeShow(item)"
              style="z-index: 1000; position: absolute"
              :class="'sixImg img' + item"
            /> -->

            <!-- 虫情测报 -->
            <img
              :src="picList[2]"
              alt=""
              v-if="radio.indexOf('2') != -1 && item == 6"
              @click="changeShow(6)"
              style="z-index: 1000; position: absolute"
              :class="'sixImg img' + item"
            />
            <!-- 监控对应的内容 -->
            <img
              src="../assets/img/zhexian.png"
              alt=""
              v-if="
                radio.indexOf('0') != -1 &&
                item == conShowIndex &&
                conShow &&
                item == 2
              "
              class="zhexianImg"
            />

            <div
              :id="'myVideoGuan' + item + 'Outer'"
              class="zhexianMp4Outer"
              style="left: 3.8rem; top: -5rem; position: absolute"
            >
              <video
                :id="'myVideoGuan' + item"
                class="
                  zhexianMp4
                  myVideo
                  video-js
                  vjs-default-skin vjs-big-play-centered
                "
                muted
                ref="videoPlayer"
                controls="true"
                autoplay
                v-show="
                  radio.indexOf('0') != -1 &&
                  item == conShowIndex &&
                  conShow &&
                  item == 2
                "
              >
                <!-- <source
                id="source"
                :src="vdoSrcGuan"
                type="application/x-mpegURL"
              /> -->
              </video>
            </div>

            <!-- 周边情况 -->
            <!-- 设备1 -->
            <img
              src="../assets/img/zhexian.png"
              alt=""
              v-if="
                radio.indexOf('0') != -1 &&
                item == conShowIndex &&
                conShow &&
                item == 1
              "
              class="zhexianImg"
            />
            <div
              :id="'myVideoSurround' + item + 'Outer'"
              class="zhexianMp4Outer"
              style="left: 3.8rem; top: -5rem; position: absolute"
            >
              <video
                :id="'myVideoSurround' + item"
                class="
                  zhexianMp4
                  myVideo
                  video-js
                  vjs-default-skin vjs-big-play-centered
                "
                muted
                loop
                ref="videoPlayer2"
                controls="true"
                autoplay
                v-show="
                  radio.indexOf('0') != -1 &&
                  item == conShowIndex &&
                  conShow &&
                  item == 1
                "
                key="bbb"
              ></video>
            </div>
            <!-- 新增5台设备7 8 9 10 11周边-->
            <div
              v-for="deviceItem in 5"
              :key="'deviceItem' + deviceItem"
              v-show="
                radio.indexOf('3') != -1 &&
                item == conShowIndex &&
                conShow &&
                item == deviceItem + 6
              "
            >
              <img src="../assets/img/zhexian.png" alt="" class="zhexianImg" />
              <div
                :id="'myVideoSurround' + item + 'Outer'"
                class="zhexianMp4Outer"
                style="left: 3.8rem; top: -5rem; position: absolute"
              >
                <video
                  :id="'myVideoSurround' + item"
                  class="
                    zhexianMp4
                    myVideo
                    video-js
                    vjs-default-skin vjs-big-play-centered
                  "
                  autoplay
                  muted
                  preload="true"
                  controls="true"
                  key="ccc"
                  :ref="'videoPlayer' + item"
                ></video>
              </div>
            </div>
            <!-- 新增5台设备12 13 14 15 16苗木-->
            <div
              v-for="deviceItem in 5"
              :key="'deviceItem333' + deviceItem"
              v-show="
                radio.indexOf('0') != -1 &&
                item == conShowIndex &&
                conShow &&
                item == deviceItem + 11
              "
            >
              <img src="../assets/img/zhexian.png" alt="" class="zhexianImg" />
              <div
                :id="'myVideoSurround' + item + 'Outer'"
                class="zhexianMp4Outer"
                style="
                  left: 3.8rem;
                  top: -5rem;
                  position: absolute;
                  width: 14.375rem;
                  height: 7.5rem;
                "
                :ref="'videoContainer' + item"
                data-fullscreen="false"
                
              >
                <video
                  :id="'myVideoSurround' + item"
                  class="
                    zhexianMp4
                    myVideo
                    video-js
                    vjs-default-skin vjs-big-play-centered
                  "
                  muted
                  loop
                  :ref="'videoPlayer' + item"
                  controls="true"
                  autoplay
                  key="bbb"
                  webkit-playsinline="true"
                >
                  Your browser is too old which doesn't support HTML5 video.
                </video>
              </div>
            </div>
            <!-- 无线商情对应的内容 -->
            <img
              src="../assets/img/chongBg.png"
              alt=""
              v-if="
                radio.indexOf('1') != -1 &&
                item == conShowIndex &&
                conShow &&
                item == 4
              "
              class="zhexianImg longZhexian"
            />
            <span
              v-if="
                radio.indexOf('1') != -1 &&
                item == conShowIndex &&
                conShow &&
                item == 4
              "
              class="zhexianSpan"
            >
              <p>生长数据</p>
              <ul>
                <li v-for="(v, i) in lifeData" :key="i">
                  {{ v.name + "：" + v.leval + v.danwei }}
                </li>
              </ul>
            </span>
            <!-- 虫情分析  好的 -->
            <img
              src="../assets/img/chongBg.png"
              alt=""
              v-if="
                radio.indexOf('2') != -1 &&
                conShow &&
                item == 6 &&
                conShowIndex == 6
              "
              class="zhexianImg"
            />
            <span
              v-if="
                radio.indexOf('2') != -1 &&
                conShow &&
                item == 6 &&
                conShowIndex == 6
              "
              class="zhexianSpan"
            >
              <p style="margin-left: 0">虫情分析</p>
              <ul>
                <li
                  v-for="(v, i) in anylseDate"
                  :key="i"
                  style="width: 100%; text-align: left"
                >
                  {{ v }}
                </li>
              </ul>
            </span>

            <img
              src="../assets/img/chongBg.png"
              alt=""
              v-if="
                radio.indexOf('2') != -1 &&
                conShow &&
                item == 5 &&
                conShowIndex == 5
              "
              class="zhexianImg"
            />
            <span
              v-if="
                radio.indexOf('2') != -1 &&
                conShow &&
                item == 5 &&
                conShowIndex == 5
              "
              class="zhexianSpan"
            >
              <p style="margin-left: 0">虫情分析</p>
              <ul>
                <li
                  v-for="(v, i) in anylseDate2"
                  :key="i"
                  style="width: 100%; text-align: left"
                >
                  {{ v }}
                </li>
              </ul>
            </span>
            <!-- 虫情分析不好的 -->
            <!-- <img src="../assets/img/badChongBg.png" alt="" v-if="radio.indexOf('2')!=-1&&item==5&&conShow==true&&conShowIndex==5" class="zhexianImg" >
            <span v-if="radio.indexOf('2')!=-1&&item==5&&conShow==true&&conShowIndex==5" class="zhexianSpan">
              <p style="margin-left:0">虫情分析</p>
              <ul>
                <li v-for="(v,i) in anylseDate" :key="i" style="width:100%;text-align:left">{{v}}</li>
              </ul>
            </span> -->
          </span>

          <ul class="areaListAll">
            <li v-for="(v, i) in areaAll" :key="i">
              <p>{{ v.num }}</p>
              <img src="../assets/img/allArea2.png" alt="" />
              <p>{{ v.name }}</p>
            </li>
            <!-- <img src="../assets/img/allA.png" alt="" /> -->
          </ul>
        </div>
        <div class="rightBody">
          <right-body></right-body>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import leftBody from "../components/leftBody";
import rightBody from "../components/rightBody";
import axios from "axios";
import { setInterval } from "timers";
import videojs from "video.js";
import "videojs-contrib-hls";
import flv from "flv.js";
import screenfull from "screenfull" 
export default {
  data() {
    return {
      vdoSrcGuan:
        "http://39.174.72.5:7086/live/cameraid/1000089%2420/substream/1.m3u8",
      vdoSrcSurround:
        "http://39.174.72.5:7086/live/cameraid/1000089%2415/substream/1.m3u8",
      vdoSrcSurroundArr: [
        " http://39.174.72.5:7086/live/cameraid/1000089%2417/substream/1.m3u8",
        " http://39.174.72.5:7086/live/cameraid/1000089%244/substream/1.m3u8",
        " http://39.174.72.5:7086/live/cameraid/1000089%2415/substream/1.m3u8",
        " http://39.174.72.5:7086/live/cameraid/1000088%241/substream/1.m3u8",
        " http://39.174.72.5:7086/live/cameraid/1000089%2420/substream/1.m3u8",
      ],
      // vdoSrcSurroundArr:[],
      area: [3, 5, 0, ".", 0, 0],
      areaAll: [
        { name: "灌溉次数/次", num: 4 },
        { name: "修葺次数/次", num: 3 },
        { name: "施肥次数/次", num: 2 },
        { name: "除草次数/次", num: 1 },
      ],
      radio: ["0", "1", "2", "3"],
      picList: [
        require("../assets/img/jiankong.png"),
        require("../assets/img/wuxianshangqing.png"),
        require("../assets/img/chongqingcebao.png"),
      ],
      pointArr: [
        "onePoint",
        "twoPoint",
        "threePoint",
        "fourPoint",
        "fivePoint",
        "sixPoint",
        "sevenPoint",
        "eightPoint",
        "ninePoint",
        "tenPoint",
        "elvenPoint",
        "twlvePoint",
        "thirteenPoint",
        "fourteenPoint",
        "fifteenPoint",
        "sixteenPoint",
      ],
      conShow: false,
      conShowArr: [
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
      ],
      conShowIndex: "",
      //  lifeData:['土壤温度 ℃','空气温度 ℃','零点温度 ℃','土壤湿度 %','空气湿度：10℃','土壤PH值：6.5PH'],
      lifeData: [
        { name: "空气温度", leval: 12, danwei: "℃" },
        { name: "露点温度", leval: 28, danwei: "℃" },
        { name: "空气湿度", leval: 20, danwei: "%" },
        { name: "土壤温度", leval: 56, danwei: "℃" },
        { name: "土壤温度", leval: 56, danwei: "℃" },
        { name: "土壤湿度", leval: 6.2, danwei: "%" },
      ],
      anylseDate: ["小绿叶蝉：0只", "虫尺蠖数：0只"],
      anylseDate2: ["小绿叶蝉：1只", "虫尺蠖数：0只", "非茶叶害虫：60只"],
      badShow: true,
      weather: {
        weather: "",
        temperature: "",
        time: "",
        day: "",
        riqi: "",
      },
      todayTianqi: "",
      tianqiArr: [
        { tianqi: "晴", tianqiSrc: require("../assets/img/sunny.png") },
        { tianqi: "暴雨", tianqiSrc: require("../assets/img/baoyu.png") },
        { tianqi: "冰雹", tianqiSrc: require("../assets/img/bingbao.png") },
        { tianqi: "大雪", tianqiSrc: require("../assets/img/daxue.png") },
        { tianqi: "多云", tianqiSrc: require("../assets/img/duoyun.png") },
        { tianqi: "雷阵雨", tianqiSrc: require("../assets/img/leizhenyu.png") },
        { tianqi: "小雪", tianqiSrc: require("../assets/img/xiaoxue.png") },
        { tianqi: "小雨", tianqiSrc: require("../assets/img/xiaoyu.png") },
        { tianqi: "阴", tianqiSrc: require("../assets/img/yin.png") },
        { tianqi: "雨夹雪", tianqiSrc: require("../assets/img/yujiaxue.png") },
        { tianqi: "阵雨", tianqiSrc: require("../assets/img/zhenyu.png") },
        { tianqi: "中雪", tianqiSrc: require("../assets/img/zhongxue.png") },
        { tianqi: "中雨", tianqiSrc: require("../assets/img/zhongyu.png") },
      ],
      now: "",
      weeks: [
        "星期日",
        "星期一",
        "星期二",
        "星期三",
        "星期四",
        "星期五",
        "星期六",
      ],
      videoElement: "",
      flvPlayer: "",
      flvPlayerList: [],
      deviceArr: [
        "W863320045447131",
        "W863320045439773",
        "W863320045140306",
        "W863320045440391",
        "W863320045444823",
      ],

      player: null,
    };
  },
  components: {
    leftBody,
    rightBody,
  },
  mounted() {
    console.log(screenfull.isEnabled)
    if(screenfull.isEnabled){
      // screenfull.toggle();
    }
    //新增农事信息
    this.$api.getFarmInfo().then((res) => {
      this.areaAll = [
        { name: "灌溉次数/次", num: res.data.water },
        { name: "修葺次数/次", num: res.data.fix },
        { name: "施肥次数/次", num: res.data.farm },
        { name: "除草次数/次", num: res.data.cut },
      ];
    });
    sessionStorage.setItem("lastClick", "");
    this.now = new Date();
    this.$api.getWeather({}).then((res) => {
      if(res.data){
        this.weather = res.data;
      let month =
        this.now.getMonth() + 1 > 9
          ? this.now.getMonth() + 1
          : "0" + (this.now.getMonth() + 1);
      let d =
        this.now.getDate() > 9 ? this.now.getDate() : "0" + this.now.getDate();
      let hour =
        this.now.getHours() > 9
          ? this.now.getHours()
          : "0" + this.now.getHours();
      let minite =
        this.now.getMinutes() > 9
          ? this.now.getMinutes()
          : "0" + this.now.getMinutes();
      this.weather.time = hour + ":" + minite;
      this.weather.week = this.weeks[this.now.getDay()];
      this.weather.riqi = this.now.getFullYear() + "-" + month + "-" + d;
      ////console.log("date", this.now.getMonth() + 1);
      for (let i in this.tianqiArr) {
        //console.log(this.tianqiArr[i].tianqi, "tianqi");
        //console.log(this.weather.weather, "tianqi2");
        if (this.tianqiArr[i].tianqi == this.weather.weather) {
          this.todayTianqi = this.tianqiArr[i].tianqiSrc;
          // //console.log(this.todayTianqi,'hhh')
        }
      }
      }
    });
    setInterval(() => {
      this.now = new Date();
      //console.log("hhh");
      this.$api.getWeather({}).then((res) => {
        this.weather = res.data;
        let month =
          this.now.getMonth() + 1 > 9
            ? this.now.getMonth() + 1
            : "0" + (this.now.getMonth() + 1);
        let d =
          this.now.getDate() > 9
            ? this.now.getDate()
            : "0" + this.now.getDate();
        let hour =
          this.now.getHours() > 9
            ? this.now.getHours()
            : "0" + this.now.getHours();
        let minite =
          this.now.getMinutes() > 9
            ? this.now.getMinutes()
            : "0" + this.now.getMinutes();
        this.weather.time = hour + ":" + minite;
        this.weather.week = this.weeks[this.now.getDay()];
        this.weather.riqi = this.now.getFullYear() + "-" + month + "-" + d;
        //console.log("date", this.now.getMonth() + 1);
        for (let i in this.tianqiArr) {
          // //console.log(this.tianqiArr[i].tianqi,"tianqi")
          // //console.log(this.weather.weather,'tianqi2')
          if (this.tianqiArr[i].tianqi == this.weather.weather) {
            this.todayTianqi = this.tianqiArr[i].tianqiSrc;
            // //console.log(this.todayTianqi,'hhh')
          }
        }
      });
    }, 30000);
    this.$api.environmentInfo({}).then((res) => {
      this.lifeData[0].leval = res.data.airTemp;
      this.lifeData[1].leval = res.data.pointTemp;
      this.lifeData[2].leval = res.data.airHumidity;
      this.lifeData[3].leval = res.data.landTemp10;
      this.lifeData[4].leval = res.data.landTemp30;
      this.lifeData[5].leval = res.data.landHumidity;
    });

    this.$api
      .insectInfo({
        terminalSerial: "AFFDD19F620E",
      })
      .then((res) => {
        this.anylseDate = [
          res.data.green
            ? "小绿叶蝉数：" + res.data.green + "只"
            : "小绿叶蝉数：0只",
          res.data.insect
            ? "虫尺蠖数：" + res.data.insect + "只"
            : "虫尺蠖数：0只",
          res.data.notPest
            ? "非茶叶害虫：" + res.data.notPest + "只"
            : "非茶叶害虫：0只",
        ];

        // //console.log("hh",1);
        // this.drawLine();
      });
    this.$api
      .insectInfo({
        terminalSerial: "062B52EBA755",
      })
      .then((res) => {
        this.anylseDate2 = [
          res.data.green
            ? "小绿叶蝉数：" + res.data.green + "只"
            : "小绿叶蝉数：0只",
          res.data.insect
            ? "虫尺蠖数：" + res.data.insect + "只"
            : "虫尺蠖数：0只",
          res.data.notPest
            ? "非茶叶害虫：" + res.data.notPest + "只"
            : "非茶叶害虫：0只",
        ];

        // //console.log("hh",1);
        // this.drawLine();
      });
  },
  methods: {
    //获取直播地址接口
    getLiveUrl(deviceNumber, mapDevice) {
      console.log(deviceNumber, mapDevice);
      this.$api.getLiveUrl({ deviceNumber }).then((res) => {
        
        if (flv.isSupported()) {
          setTimeout( ()=> {
            // 这里加了个200ms的定时器,原因是因为抓取元素之后vue无法瞬间响应
            this.flvPlayer = flv.createPlayer(
              {
                url: res.flv.replace(
                  "https://iot.xa.com",
                  "http://125.124.209.204:22222"
                ),
                type: "flv",

                enableWorker: true, // 浏览器端开启flv.js的worker,多进程运行flv.js
                isLive: true, // 直播模式
                hasAudio: false, // 关闭音频
                hasVideo: true,
                controls: true,
                autoplay: true,
                controlBar: {
                  fullscreenToggle: true,
                },

                stashInitialSize: 128,
                enableStashBuffer: true, // 播放flv时，设置是否启用播放缓存，只在直播起作用。
                fixAudioTimestampGap: false,
              },
              {
                enableStashBuffer: false,
                stashInitialSize: 128,
                autoCleanupSourceBuffer: true,
                headers: {
                  Authorization:
                    "Basic " +
                    btoa(unescape(encodeURIComponent("username:password"))),
                },
              }
            );

            this.flvPlayer.attachMediaElement(mapDevice);
            console.log(this.flvPlayer);
            if (res.flv != "" && res.flv !== null) {
              this.flvPlayer.load();
              this.flvPlayer.play();
              this.flvPlayer.on(flv.Events.ERROR, (errType, errDetail) => {
                console.log("errType",errType,errDetail)
                if (this.flvPlayer) {
                  this.reloadVideo(this.flvPlayer, mapDevice);
                }
              });
              console.log(this.flvPlayerList)
              this.flvPlayerList.push(this.flvPlayer);
            }
          }, 200);
        }
      });
      // var tagArr=document.getElementsByTagName('video')
    },
    // 重新连接
    reloadVideo(flvPlayer, mapDevice) {
      this.destoryVideo(flvPlayer);
      this.getLiveUrl(flvPlayer, mapDevice);
    },
    //销毁断流方法
    destoryVideo(flvPlayer) {
      console.log(flvPlayer);
      if (flvPlayer) {
        flvPlayer.pause();
        flvPlayer.unload();
        flvPlayer.detachMediaElement();
        flvPlayer.destroy();
        flvPlayer = null;
      }
    },
    getVideo(videoPlayer, nowPlayVideoUrl) {
      console.log(videoPlayer);
      let tempPlayer = videoPlayer;
      let options = {
        autoplay: true, // 设置自动播放
        muted: true, // 设置了它为true，才可实现自动播放,同时视频也被静音 （Chrome66及以上版本，禁止音视频的自动播放）
        preload: "auto", // 预加载
        controls: true, // 显示播放的控件
        notSupportedMessage: "此视频暂无法播放，请稍后再试",
        errorDisplay: true, //错误显示
        dataSetup: "",
        hls: {
          withCredentials: false,
        },
        src: nowPlayVideoUrl,
        type: "application/x-mpegURL",
        sources: [
          {
            src: nowPlayVideoUrl,
            type: "application/x-mpegURL", // 告诉videojs,这是一个hls流}]
          },
        ],
      };

      this.player = videojs("" + tempPlayer, options, function onPlayerReady() {
        this.on("error", function () {
          // 播放过程中由于网络或其他原因产生的等待，此时视频播放暂停，等网络恢复后会自动执行【playing】自动播放
          //这里是只要报错就去执行重新请求直播流的函数
         
          this.player.load();
      this.player.ready();
      this.player.play();
        });
      });
      //关键代码， 动态设置src，才可实现换台操作

      console.log();
      this.player.src([
        {
          src: nowPlayVideoUrl,
          type: "application/x-mpegURL", // 告诉videojs,这是一个hls流
        },
      ]);
      this.player.load();
      this.player.ready();
      this.player.play();
    },
    changeShow(item) {
      //console.log("chulai", item);
      //点谁让谁的层级提高

      var imgArr = document.querySelectorAll(".sixImg");
      for (let i in imgArr) {
        imgArr[i].style ? (imgArr[i].style.zIndex = 1000) : "";
      }
      var imgSelect = document.querySelector(".img" + item);
      imgSelect.style
        ? (imgSelect.style.zIndex = 1004)
        : (imgSelect.style.zIndex = 1000);
      if (sessionStorage.getItem("lastClick") == item) {
        this.conShow = !this.conShow;
      } else {
        this.conShow = true;
      }
      sessionStorage.setItem("lastClick", item);
      //console.log(this.conShow, "conShow");
      this.conShowIndex = item;
      if (this.player && this.player.error_ == null && this.conShow == false) {
        console.log(this.player);
        let playId = this.player.id_;
        this.player.dispose();
        console.log(document.getElementById(playId + "Outer"));
        let outer = document.getElementById(playId + "Outer");
        var videoTemp = document.createElement("video");
        videoTemp.innerText="Your browser is too old which doesn't support HTML5 video."
        videoTemp.className =
          "zhexianMp4 myVideo video-js vjs-default-skin vjs-big-play-centered";
        videoTemp.id = playId;
        videoTemp.muted = true;
        videoTemp.controls = true;
        videoTemp.loop = true;
        videoTemp.autoplay = true;
        videoTemp.style = "width:14.375rem;height:7.5rem;z-index:1003";
        outer.appendChild(videoTemp);

        console.log(document.getElementById(playId + "Outer"));
      }
      this.conShowArr[item - 1] = !this.conShowArr[item - 1];
      if (item == 5) {
        this.badShow = !this.badShow;
      }
      //console.log(this.conShowIndex, this.conShow);
      //console.log(this.conShowArr, "conShowArr");
      //console.log("vdoSrc");

      var guan = document.querySelector("#myVideoGuan2");

      if (this.radio.indexOf("0") != -1 && this.conShow && item == 2) {
        guan.style.display = "block";
        this.$nextTick(() => {
          this.getVideo("myVideoGuan2", this.vdoSrcGuan);
        });
      } else {
        guan.style.display = "none";
      }

      var surround1 = document.querySelector("#myVideoSurround1");

      if (this.radio.indexOf("0") != -1 && this.conShow && item == 1) {
        surround1.style.display = "block";

        this.$nextTick(() => {
          this.getVideo("myVideoSurround1", this.vdoSrcSurround);
        });
      } else {
        surround1.style.display = "none";
      }

      //新增四台设备

      var surroundArr = [
        "surround7",
        "surround8",
        "surround9",
        "surround10",
        "surround11",
        "surround12",
        "surround13",
        "surround14",
        "surround15",
        "surround16",
      ];

      for (let i in surroundArr) {
        //console.log("新增四台设备", surroundArr[i], i + 7, item, item == i + 7);
        // if (item == (Number(i) + 7)) {
        //   //console.log("新增四台设备",surroundArr[i])
        surroundArr[i] = document.querySelector(
          "#myVideoSurround" + (Number(i) + 7)
        );

        // console.log( this.radio)
        //m3u8格式处理
        if (i < 5) {
          if (
            this.radio.indexOf("3") != -1 &&
            this.conShow &&
            i < 5 &&
            item == Number(i) + 7
          ) {
            console.log(surroundArr);
            surroundArr[i].style.display = "block";
            surroundArr[i].style.zIndex = 1004;
            this.$nextTick(() => {
              this.getVideo(
                "myVideoSurround" + item,
                this.vdoSrcSurroundArr[i]
              );
            });
            this.$nextTick(() => {
              document.getElementById(
                "myVideoSurround" + item + "_html5_api"
              ).style.zIndex = "";
            });
          } else {
            surroundArr[i].style.display = "none";
          }
        } else {
          // flv格式处理
          if (
            this.radio.indexOf("0") != -1 &&
            this.conShow &&
            i > 4 &&
            item == Number(i) + 7
          ) {
            surroundArr[i].style.display = "block";
            surroundArr[i].style.zIndex = 1004;

            if (this.flvPlayerList.length > 3) {
              this.destoryVideo(this.flvPlayerList[0]);
            }
            console.log(item);
            this.$nextTick(() => {
              document
                .getElementById("myVideoSurround" + item)
                .addEventListener("click", function () {
                  console.log("hhh");
                  surroundArr[i].controls = true;
                });
            });
            this.getLiveUrl(this.deviceArr[Number(i) - 5], surroundArr[i]);
          } else {
            surroundArr[i].style.display = "none";
            surroundArr[i].style.zIndex = 1000;
            //console.log("新增四台设备2", surroundArr[i]);
          }
        }

        // }
      }
    },
    pasueVideo(id) {
      var play = id.play();

      if (play) {
        play
          .then(() => {
            // 视频频加载成功
            // 视频频的播放需要耗时
            setTimeout(() => {
              // 后续操作
              id.pause();

              //console.log("done.");
            }, id.duration * 1000); // video[0].duration 为视频频的时长，单位为秒
          })
          .catch((e) => {
            // 视频频加载失败
          });
      }
    },
    checkBoxChange(v) {
      //console.log(this.radio, v);
      //点谁让谁的层级提高
      var imgArr = document.querySelectorAll(".sixImg");
      for (let i in imgArr) {
        imgArr[i] && imgArr[i].style ? (imgArr[i].style.zIndex = 1000) : "";
      }
      var imgSelect = document.querySelector(".img" + this.conShowIndex);
      imgSelect && imgSelect.style ? (imgSelect.style.zIndex = 1004) : "";
      var guan = document.querySelector("#myVideoGuan2");

      if (
        this.radio.indexOf("0") != -1 &&
        this.conShow &&
        this.conShowIndex == 2
      ) {
        guan.style.display = "block";

        this.getLiveUrl("W863320045447131", guan);
        if (this.flvPlayerList.length > 3) {
          this.destoryVideo(this.flvPlayerList[0]);
        }
      } else {
        guan.style.display = "none";
      }

      var surround1 = document.querySelector("#myVideoSurround1");

      if (
        this.radio.indexOf("0") != -1 &&
        this.conShow &&
        this.conShowIndex == 1
      ) {
        surround1.style.display = "block";

        this.getLiveUrl("W863320045447131", surround1);
        if (this.flvPlayerList.length > 3) {
          this.destoryVideo(this.flvPlayerList[0]);
        }
      } else {
        // this.pasueVideo(surroundVideo);
        surround1.style.display = "none";
      }
      //新增四台设备
      var surroundArr = [
        "surround7",
        "surround8",
        "surround9",
        "surround10",
        "surround11",
        "surround12",
        "surround13",
        "surround14",
        "surround15",
        "surround16",
      ];

      for (let i in surroundArr) {
        //console.log("新增四台设备", surroundArr[i], i + 7, item, item == i + 7);
        // if (item == (Number(i) + 7)) {
        //   //console.log("新增四台设备",surroundArr[i])
        surroundArr[i] = document.querySelector(
          "#myVideoSurround" + (Number(i) + 7)
        );
        // console.log( this.radio)
        //m3u8格式处理
        if (i < 5) {
          if (
            this.radio.indexOf("3") != -1 &&
            this.conShow &&
            i < 5 &&
            this.conShowIndex == Number(i) + 7
          ) {
            console.log(surroundArr);
            surroundArr[i].style.display = "block";
            surroundArr[i].style.zIndex = 1004;

            this.$nextTick(() => {
              this.getVideo(
                "myVideoSurround" + this.conShowIndex,
                this.vdoSrcSurroundArr[i]
              );
            });
          } else {
            surroundArr[i].style.display = "none";
          }
        } else {
          // flv格式处理
          if (
            this.radio.indexOf("0") != -1 &&
            this.conShow &&
            i > 4 &&
            this.conShowIndex == Number(i) + 7
          ) {
            surroundArr[i].style.display = "block";

            surroundArr[i].style.zIndex = 1004;

            if (this.flvPlayerList.length > 3) {
              this.destoryVideo(this.flvPlayerList[0]);
            }

            this.getLiveUrl(this.deviceArr[Number(i) - 5], surroundArr[i]);
          } else {
            surroundArr[i].style.display = "none";
            surroundArr[i].style.zIndex = 1000;
            //console.log("新增四台设备2", surroundArr[i]);
          }
        }

        // }
      }

      // if(this.radio.indexOf('0')){
      //   this.conShowArr[0]=false
      //   this.conShowArr[1]=false
      // }
      // if(this.radio.indexOf('1')){
      //   this.conShowArr[2]=false
      //   this.conShowArr[3]=false
      // }
      // if(this.radio.indexOf('2')){
      //    this.conShowArr[6]=false

      // }
    },
    goHome() {
      location.href = "https://admin.anding.forkon.com.cn/#/manger";
    },
    fullScreen() {
      console.log(this)
      if (this._isMobile()) {
       screenfull.toggle(); 
    } 
      
    },
_isMobile() {
	 let flag = navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i)
	 return flag;
}
  },
};
</script>
<style scoped lang="scss">
* {
  margin: 0;
  padding: 0;
}
.BG {
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: -100;
}
.box {
  width: 100%;
  height: 100%;
  font-family: PingFang SC;
  position: fixed;
}
.header {
  width: 100%;
  position: relative;
  .headerBg {
    width: 100%;
    position: absolute;
    z-index: -1;
  }
  .content {
    display: flex;
    justify-content: space-between;
    .leftContent {
      width: 40%;
      height: 65px;
      display: flex;
      justify-content: left;
      align-items: center;
      //   line-height: 55px;
      margin-left: 40px;
      color: #ffffff;
      // background-color: red;
      .sunnyImg {
        vertical-align: middle;
        width: 28px;
        // margin-bottom: 8px;
      }
    }
    .centerContent {
      width: 30%;
      font-size: 30px;
      font-weight: 800;
      height: 65px;
      line-height: 65px;
      color: #ffffff;
      text-align: center;
      text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.5);
      letter-spacing: 8px;
    }
    .rightContent {
      // background-color: #fff;
      width: 35%;
      margin-right: 40px;
      display: flex;
      justify-content: right;
      align-items: center;

      position: relative;
    }
    .rightContent a {
      position: absolute;
      z-index: 1;
      display: inline-block;
      // background-color: red;
      width: 120px;
      text-align: center;
      cursor: pointer;
      font-size: 16px;

      font-weight: bold;
      text-decoration: none;
      color: #ffffff;
    }
    .rightContent a:first-of-type {
      right: 410px;
    }
    .rightContent a:nth-of-type(2) {
      right: 240px;
    }
    .rightContent a:nth-of-type(3) {
      right: 70px;
    }
    .rightContent img {
      width: 120px;
      height: 32px;
      position: absolute;
      z-index: 0;
    }
    .rightContent img:last-of-type {
      right: 0px;
      width: 30px;
      height: 30px;
    }
    .rightContent img:nth-of-type(3) {
      right: 70px;
    }
    .rightContent img:nth-of-type(2) {
      right: 240px;
    }
    .rightContent img:nth-of-type(1) {
      right: 410px;
    }
    .leftContent > span:nth-of-type(5) {
      font-size: 24px;
      font-weight: bold;
    }

    .leftContent > span:nth-of-type(4) {
      font-size: 14px;

      // display: inline-block;
      margin-right: 40px;
    }
  }
}
.body {
  width: 96.875%;
  // margin: 40px auto 0;
  margin: 42px 30px 0;
  display: flex;
  justify-content: space-between;
  .leftBody {
    width: 481px;
  }
  .rightBody {
    width: 481px;
  }
}
.map {
  height: 90%;
  position: absolute;
  left: 0;
  right: 0;
  margin: 0px auto;
  top: 80px;
  z-index: -1;
}
.centerBody {
  width: 45%;
  // background-color: red;
  position: relative;
}
li {
  list-style: none;
}
.areaList {
  width: 60%;
  display: flex;
  flex-wrap: nowrap;
  margin: 13px auto;
  display: flex;
  justify-content: center;
  font-size: 20px;
  // background: #000;
  line-height: 31px;
  color: #ffffff;
  text-align: center;
}
.areaList img {
  // width: 50px;
  width: 80%;
}
.areaList li {
  position: relative;
  // background-color: #fff;
}
.areaList li p {
  position: absolute;
  top: 12%;

  left: 30%;
}
.areaListAll {
  width: 100%;
  // background-color: red;
  display: flex;
  flex-wrap: nowrap;
  position: absolute;
  bottom: 0px;
  justify-content: space-around;
  font-size: 6px;
  font-weight: bold;
  color: rgba(255, 255, 255, 0.8);
}
.areaListAll img {
  // width: 100px;
  width: 100%;
}
.areaListAll li {
  width: 20%;
  text-align: center;
  position: relative;
  // background-color: red;
}
.areaListAll li p:first-of-type {
  position: absolute;
  top: 8%;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  font-size: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
}
.device {
  // background-color: #fff;
  width: 200px;
  height: 200px;
  margin-top: 180px;
}
.device li {
  margin: 20px 0;
  color: #ffffff !important;
}

.point {
  display: inline-block;
  // width: 36px;
  // height: 36px;
  // background-color: aqua;
  // border-radius: 50%;
}
.fourPoint {
  position: absolute;
  top: 180px;
  left: 400px;
}
.twoPoint {
  position: absolute;
  top: 320px;
  left: 520px;
}
.threePoint {
  position: absolute;
  top: 450px;
  left: 300px;
}
.fivePoint {
  position: absolute;
  top: 450px;
  left: 600px;
}
.onePoint {
  position: absolute;
  top: 580px;
  left: 550px;
}
.sixPoint {
  position: absolute;
  top: 500px;
  left: 350px;
}
.sevenPoint {
  position: absolute;
  top: 580px;
  left: 180px;
}
.eightPoint {
  position: absolute;
  top: 300px;
  left: 350px;
}
.ninePoint {
  position: absolute;
  top: 150px;
  left: 550px;
}
.tenPoint {
  position: absolute;
  top: 650px;
  left: 450px;
}
.elvenPoint {
  position: absolute;
  top: 250px;
  left: 600px;
}
.twlvePoint {
  position: absolute;
  top: 580px;
  left: 400px;
}
.thirteenPoint {
  position: absolute;
  top: 500px;
  left: 420px;
}
.fourteenPoint {
  position: absolute;
  top: 400px;
  left: 400px;
}
.fifteenPoint {
  position: absolute;
  top: 450px;
  left: 500px;
}
.sixteenPoint {
  position: absolute;
  top: 550px;
  left: 480px;
}
.zhexianImg {
  width: 300px;
  position: absolute;
  top: -90px;
  left: 15px;
  z-index: 1003;
}

.zhexianMp4 {
  width: 230px;
  height: 120px;
  position: absolute;

  z-index: 1003;
  background-color: black;
}

.myVideoGuan {
  position: absolute;
  top: -83px;
  left: 58px;
}
.video-js {
  width: 230px !important;
  height: 120px !important;
}
.zhexianSpan {
  display: inline-block;

  width: 270px;
  height: 110px;

  position: absolute;
  top: -85px;
  left: 75px;
  z-index: 1003;
  // background-color: red;
}
.zhexianSpan ul {
  width: 100%;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  color: rgba(255, 255, 255, 0.8);
  font-size: 9px;
  align-content: flex-end;
}
.zhexianSpan li {
  width: 50%;
  line-height: 23px;
  text-align: left;
  // background-color: red;
}
.zhexianSpan p {
  margin: 5px 5px;
  font-size: 12px;

  font-weight: bold;

  color: #ffffff;
}
.sixImg {
  width: 45px;
  height: 45px;
}
.longZhexian {
  width: 350px;
}
</style>
