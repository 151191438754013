<template>
  <div class="price">
    <div class="date">
      <el-date-picker
        v-show="activeDate == 1"
        v-model="priceDate"
        type="month"
        placeholder="选择月"
        value-format="yyyy,MM"
        @change="selectDate"
        :clearable="false"
        :editable="false"
      >
      </el-date-picker>
      <el-date-picker
        v-show="activeDate == 0"
        v-model="valueYear"
        type="year"
        placeholder="选择年"
        value-format="yyyy"
        :clearable="false"
        :editable="false"
      >
      </el-date-picker>
      <span :class="activeDate == 0 ? 'active' : ''" @click="changeDate(0)"
        >年</span
      >
      <span :class="activeDate == 1 ? 'active' : ''" @click="changeDate(1)"
        >月</span
      >
    </div>
    <div id="myChart2"></div>
  </div>
</template>

<script>
export default {
  name: "price",
  data() {
    return {
      priceDate: "",
      days: "",
      month: "",
      valueYear: "",
      activeDate: 1,
      timeStr: "",
      myChart2: "",
      echartsOption: {
        width: "300px",
        tooltip: {
          trigger: "axis",
        },
        color: ["#0080FF", "#FACC14", "#EF4864", "#2FC25B"], //图列颜色
        legend: {
          orient: "horizontal",
          icon: "circle",
          textStyle: {
            fontSize: this.transformFontSize(15),
          },
          itemStyle: { normal: { color: "inherit" } },
          itemGap: 10,
          bottom: "0",
          data: [
            {
              name: "安顶云雾茶",
              textStyle: {
                color: "#0080FF",
              },
            },
            {
              name: "拔山绿茶",
              textStyle: {
                color: "#FACC14",
              },
            },
            // {
            //   name: "高桥绿茶",
            //   textStyle: {
            //     color: "#EF4864",
            //   },
            // },
            {
              name: "龙井绿茶",
              textStyle: {
                color: "#2FC25B",
              },
            },
          ],
        },
        grid: {
          width: "85%",
          height: "65%",
          bottom: "11%",
          left: "1%",
          containLabel: true,
        },
        xAxis: {
          data: [],
          min: 0,
          axisLine: {
            lineStyle: {
              color: "#DCD2CF",
            },
          },
          axisTick: {
            show: false,
          },
          // axisLabel :{
          //     interval:0
          // },
        },
        yAxis: {
          axisLine: {
            lineStyle: {
              color: "#DCD2CF",
            },
          },
          nameTextStyle: {
            fontSize: this.transformFontSize(10),
          },
          min: 0,
          max: 500,
          axisTick: {
            show: false,
          },
          splitLine: {
            lineStyle: {
              type: "dashed",
              color: "#4D5B6B",
            },
          },
        },
        series: [
          {
            name: "安顶云雾茶",
            type: "line",
            data: [0, 20, 36, 10, 10, 20],

            showSymbol: false,
            smooth: true,
          },
          // {
          //   name: "拔山绿茶",
          //   type: "line",
          //   data: [10, 5, 32, 7, 6, 9],

          //   showSymbol: false,
          //   smooth: true,
          // },
          // {
          //   name: "高桥绿茶",
          //   type: "line",

          //   data: [8, 20, 16, 20, 15, 10],
          //   showSymbol: false,
          //   smooth: true,
          // },
          // {
          //   name: "龙井绿茶",
          //   type: "line",

          //   data: [20, 30, 70, 40, 16, 9],
          //   showSymbol: false,
          //   smooth: true,
          // },
        ],
      },
    };
  },
  watch: {
    valueYear() {
      //console.log("年变化");
      if (this.activeDate == 0) {
        this.getTrendData();
      }
    },
    priceDate() {
      //console.log("月变化");
      if (this.activeDate == 1) {
        this.getTrendData();
      }
    },
  },
  mounted() {
    //填充月份
    this.valueYear = this.doHandleDate().split(",")[0];
    this.month = new Array(12).fill(1).map((item, index) => {
      return 1 + index;
    });
    this.getDays(this.doHandleDate().split(",")[1] - 0);
    this.echartsOption.xAxis.data = this.days;
    this.echartsOption.xAxis.name = "日";
    this.drawLine();
    this.priceDate = this.doHandleDate();
    //console.log(this.priceDate);
    // window.onresize=function(){
    //   this.drawLine()
    // }
    window.addEventListener("resize", () => {
      this.$nextTick(() => {
        this.myChart2.resize();
      });
    });
  },
  methods: {
    transformFontSize(fontsize) {
      const width = window.screen.width;
      const ratio = width / 1920;
      return parseInt(fontsize * ratio);
    },
    getTrendData() {
      //console.log("进来");
      if (this.activeDate == 0) {
        this.timeStr = this.valueYear;
      } else {
        //    this.timeStr=this.shipmentDate
        if (this.priceDate) {
          this.timeStr =
            this.priceDate.split(",")[0] + "-" + this.priceDate.split(",")[1];
        }
      }
      if (this.timeStr) {
        this.$api
          .getTrendData({
            dataType: 3,
            timeStr: this.timeStr,
          })
          .then((res) => {
            //console.log(res);
            let max = 10;
            let arr1 = [],
              arr2 = [],
              arr3 = [],
              arr4 = [];
            for (let i in res.data) {
              var maxTemp = Math.max(
                res.data[i].ownPrice,
                res.data[i].competitorOne,
                res.data[i].competitorTwo
              );
              max = maxTemp > max ? maxTemp : max;
              arr1.push([
                Number(res.data[i].timeStr) - 1,
                res.data[i].ownPrice,
              ]);
              arr2.push([
                Number(res.data[i].timeStr) - 1,
                res.data[i].competitorOne,
              ]);
              arr3.push([
                Number(res.data[i].timeStr) - 1,
                res.data[i].competitorTwo,
              ]);
              arr4.push([
                Number(res.data[i].timeStr) - 1,
                res.data[i].competitorThree,
              ]);
            }

            (this.echartsOption = {
              // width: "300px",
              tooltip: {
                show: true,
                trigger: "axis",
                textStyle: {
                  fontSize: this.transformFontSize(15),
                },
                formatter: (params, ticket, callback) => {
                  var htmlStr = "";
                  for (var i = 0; i < params.length; i++) {
                    var param = params[i];
                    var xName = param.name; //x轴的名称
                    var seriesName = param.seriesName; //图例名称
                    var value = param.value; //y轴值
                    var color = param.color; //图例颜色
                    // //console.log("param", param);
                    if (i === 0) {
                      htmlStr +=
                        this.timeStr +
                        "-" +
                        (xName > 9 ? xName : "0" + xName) +
                        "<br/>"; //x轴的名称
                    }
                    htmlStr += "<div>";

                    // 具体显示的数据【字段名称：seriesName，值：value】
                    // 这里判断一下name，如果是我们需要特殊处理的，就处理
                    if (seriesName === "额外信息") {
                      // 前面一条线，后面一条线【具体样式自己写】
                      htmlStr +=
                        '<div style="border: 1px solid #FFEB3B"></div>';
                      htmlStr += "XXXXX：" + value;
                      htmlStr +=
                        '<div style="border: 1px solid #FFEB3B"></div>';
                    } else {
                      // 正常显示的数据，走默认
                      htmlStr +=
                        '<span style="margin-right:5px;display:inline-block;width:10px;height:10px;border-radius:5px;background-color:' +
                        color +
                        ';"></span>';
                      htmlStr += seriesName + ":" + value[1];
                    }

                    htmlStr += "</div>";
                  }
                  return htmlStr;
                },
              },
              // color: ["#0080FF", "#FACC14", "#EF4864", "#2FC25B"], //图列颜色
              color: ["#0080FF", "#FACC14", "#2FC25B"], //图列颜色
              legend: {
                orient: "horizontal",
                icon: "circle",
                textStyle: {
                  fontSize: this.transformFontSize(15),
                },
                itemStyle: { normal: { color: "inherit" } },
                itemGap: 10,
                bottom: "-2%",
                data: [
                  {
                    name: "安顶云雾茶",
                    textStyle: {
                      color: "#0080FF",
                    },
                  },
                  // {
                  //   name: "拔山绿茶",
                  //   textStyle: {
                  //     color: "#FACC14",
                  //   },
                  // },
                  // {
                  //   name: "高桥绿茶",
                  //   textStyle: {
                  //     color: "#EF4864",
                  //   },
                  // },
                  // {
                  //   name: "龙井绿茶",
                  //   textStyle: {
                  //     color: "#2FC25B",
                  //   },
                  // },
                ],

                // itemGap: 30,
              },
              grid: {
                width: "85%",
                height: "65%",
                bottom: "11%",
                left: "1%",
                containLabel: true,
              },
              xAxis: {
                data: this.activeDate == 0 ? this.month : this.days,
                min: 0,
                axisLine: {
                  lineStyle: {
                    color: "#DCD2CF",
                  },
                },
                nameTextStyle: {
                  fontSize: this.transformFontSize(15),
                },
                axisLabel: {
                  textStyle: {
                    fontSize: this.transformFontSize(15),
                  },
                },
                axisTick: {
                  show: false,
                },
                // axisLabel :{
                //     interval:0
                // },
              },
              yAxis: {
                axisLine: {
                  lineStyle: {
                    color: "#DCD2CF",
                  },
                },
                axisLabel: {
                  textStyle: {
                    fontSize: this.transformFontSize(15),
                  },
                },
                nameTextStyle: {
                  fontSize: this.transformFontSize(15),
                },
                min: 0,
                max: max,
                axisTick: {
                  show: false,
                },
                name: "斤",
                splitLine: {
                  lineStyle: {
                    type: "dashed",
                    color: "#4D5B6B",
                  },
                },
              },
              series: [
                {
                  name: "安顶云雾茶",
                  type: "line",
                  data: arr1,

                  showSymbol: true,
                  smooth: true,
                },
                // {
                //   name: "拔山绿茶",
                //   type: "line",
                //   data: arr2,

                //   showSymbol: false,
                //   smooth: true,
                // },
                // {
                //   name: "高桥绿茶",
                //   type: "line",

                //   data: arr3,
                //   showSymbol: false,
                //   smooth: true,
                // },
                // {
                //   name: "龙井绿茶",
                //   type: "line",

                //   data: arr3,
                //   showSymbol: false,
                //   smooth: true,
                // },
              ],
            }),
              //console.log("echartsOption", this.echartsOption);
              // this.echartsOption.series.data=[[2,103],[10,500.12],[15,700],[30,901.9]]
              this.$nextTick(() => {
                //console.log("series", this.echartsOption.series.data);
                this.drawLine();
              });
          });
      }
    },
    drawLine() {
      this.myChart2 = this.$echarts.init(document.getElementById("myChart2"));
      this.myChart2.setOption(this.echartsOption);
      this.myChart2.resize();
    },
    changeDate(value) {
      this.activeDate = value;
      if (value == 0) {
        //年
        this.echartsOption.xAxis.data = this.month;
        this.echartsOption.xAxis.name = "月";
        // this.drawLine();
      } else {
        //月
        this.echartsOption.xAxis.data = this.days;
        this.echartsOption.xAxis.name = "日";
        // this.drawLine();
      }
      //console.log("echartsOption1", this.echartsOption);
      this.getTrendData();
    },
    selectDate(value) {
      let year = value.split(",")[0];
      let mon = value.split(",")[1];
      this.getDays(mon - 0);
      if (this.activeDate == 1) {
        this.echartsOption.xAxis.data = this.days;
        this.drawLine();
      }
    },
    //获取当前时间
    doHandleDate() {
      var myDate = new Date();
      var tYear = myDate.getFullYear();
      var tMonth = myDate.getMonth();
      var m = tMonth + 1;
      if (m.toString().length == 1) {
        m = "0" + m;
      }
      return tYear + "," + m;
    },
    getDays(mon) {
      //传入月份mon  类型为number

      let day_31 = [1, 3, 5, 7, 8, 10, 12];
      let day_30 = [4, 6, 9, 11];
      let year = this.doHandleDate().split(",")[0];
      //月份为31天
      if (day_31.indexOf(mon) != -1) {
        this.days = new Array(31).fill(1).map((item, index) => {
          return 1 + index;
        });
        return false;
      }
      //月份为30天
      if (day_30.indexOf(mon) != -1) {
        this.days = new Array(30).fill(1).map((item, index) => {
          return 1 + index;
        });
        return false;
      }
      //月份为2时判断当前是平年还是闰年
      //闰年
      if ((year % 4 == 0 && year % 100 !== 0) || year % 400 == 0) {
        this.days = new Array(29).fill(1).map((item, index) => {
          return 1 + index;
        });
        return false;
      }
      //平年
      this.days = new Array(28).fill(1).map((item, index) => {
        return 1 + index;
      });
    },
  },
};
</script>

<style scoped lang="scss">
.price {
  .date {
    background: transparent;
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
    span {
      display: inline-block;
      width: 50px;
      height: 35px;
      border: 1px solid rgba(102, 255, 255, 0.09803921568627452);
      border-radius: 2px;
      text-align: center;
      line-height: 35px;
      color: white;
      cursor: pointer;
      &:first-of-type {
        margin-right: 5px;
      }
    }
    .active {
      border-color: rgb(2, 173, 253);
      border-radius: 2px;
    }
  }
}
#myChart2 {
  width: 100%;
  height: 260px;
  margin: 0px auto;
  // background: red;
}
</style>
